export const ApiName = {
  generateGwtToken: "access",
  checkOperator: "checkOperator?msisdn=",
  checkUser: "checkUser?msisdn=",
  signupweb: "sendWebOTP",
  signupwebActive: "login/sendOTP",
  verifyOtp: "verifyWebOTP",
  verifyOtpActive:"login/verifyOTP",
  subscribe: "subscribe?msisdn=",
  getFLow: "getFlow",
  awarenessFLow: "awareness",
  loginWeb: "loginWeb",
  ffSubscribe: "ffSubscribe",
  utm_list: "utms",
  unSubscribeWeb: "unSubscribeWeb?msisdn=",
  changePackageWeb:"changePackageWeb",
  package_list:"package"
};
