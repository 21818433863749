import React, { useEffect, useState } from "react";

import { Routes, Route } from "react-router-dom";
import { fetchApiPost } from "./utils/FetchApi";
import { ApiName } from "./Constant/ApiName";
import AwarenessScreen from "./screens/AwarenessScreen";

// type Props = {
//   jwtToken: string | null;
// };

const LandingRoute = () => {
  // State
  const [jwtToken, setJwtToken] = useState<string | null>(null);

  useEffect(() => {
    handleGenerateJwtToken();
  }, []);

  // function
  const handleGenerateJwtToken = async () => {
    try {
      let data: any = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      const response = await fetchApiPost(null, ApiName.generateGwtToken, data);

      if (response.status === 200) {
        setJwtToken(response.access_token);
      }
    } catch (error) {
      console.log("Jwt token" + error);
    }
  };

  return (
    <>
      <Routes>
        <Route
          path="/awareness"
          element={<AwarenessScreen jwtToken={jwtToken} />}
        />
      </Routes>
    </>
  );
};

export default LandingRoute;
