import React, { useEffect, useState } from "react";
import Awareness from "../pages/Awareness";
import { useNavigate } from "react-router-dom";
import { encode as base64_encode } from "base-64";
import { ApiName } from "../Constant/ApiName";
import { fetchApiGet } from "../utils/FetchApi";
import tagManagerEvents from "../utils/GoogleTagManager";
import TagManager from "react-gtm-module";

type Props = {
  jwtToken: string | null;
};

let adNetwork = "";
const AwarenessScreen = (props: Props) => {
  const navigate = useNavigate();
  const [utm, setUtm] = useState<string | null>("");

  useEffect(() => {
    if (props.jwtToken != null) {
      handleUtmGet();
    }
  }, [props.jwtToken]);

  useEffect(() => {
    setTimeout(() => {
      gtmInitialization();
    }, 1000);
  }, []);

  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-5JFD9W2K",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const handleUtmGet = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const utmSource: string | null = searchParams.get("utm_source");
    setUtm(utmSource);
    if (utmSource) {
      handleCheckUtms(utmSource);
    }
    handleGetFlow(utmSource);
  };

  const handleCheckUtms = async (utm: string | null) => {
    try {
      let response = await fetchApiGet(props.jwtToken, ApiName.utm_list);

      if (response.status === 200) {
        {
          response.response.map((e: any) => {
            if (utm === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (error) {
      console.log("check utm error", error);
    }
  };

  const handleGetFlow = async (_utm: string | null) => {
    try {
      let endPoint = `${ApiName.awarenessFLow}?utm_source=${_utm}`;
      let response = await fetchApiGet(props.jwtToken, endPoint);
      //   response={
      //     "status": 200,
      //     "response": {
      //         "isSpecialFlow": true,
      //         "fastFlow": true,
      //         "msisdn": "3009109215",
      //         "otp": "1344",
      //         "milliseconds": 0,
      //         "signIn": false,
      //         "package": "post",
      //         "network": ""
      //     }
      // }

      if (response.response.code === "0" && response.status === 200) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("SUBSCRIBE", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("SUBSCRIBE", "econceptions");
        } else {
          tagManagerEvents("SUBSCRIBE", _utm);
        }
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(response.response.msisdn);
        let _package = "";
        if (response.response.package.toLowerCase() === "postpaid") {
          _package = "postpaid";
        } else {
          _package = "prepaid";
        }
        let encodedPackage = base64_encode(_package);
        url = `${url}/home?user=${encodedMsisdn}&package=${encodedPackage}`;

        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        tagManagerEvents("SUBSCRIBE_FAIL", _utm);
        navigateToHome("");
      }

      // if (response.status === 200) {
      //   if (
      //     response.response.msisdn !== "" &&
      //     response.response.isSpecialFlow === true &&
      //     response.response.otp !== ""
      //   ) {
      //     const apiName =
      //       response.response.isSpecialFlow && response.response.fastFlow
      //         ? `${ApiName.ffSubscribe}?msisdn=${response.response.msisdn}&utm_source=${_utm} `
      //         : ApiName.subscribe + response.response.msisdn;
      //     handleSubscribeWeb(
      //       apiName,
      //       _utm,
      //       response.response.msisdn,
      //       response.response.package
      //     );
      //   } else {
      //       navigateToHome("")
      //     tagManagerEvents("WIFI_USER", utm);
      //   }
      // }
    } catch (error) {
      navigateToHome("");
      console.log("get flow error" + error);
    }
  };

  // const handleSubscribeWeb = async (
  //   _ApiName: string,
  //   _utm: string | null,
  //   msisdn: string,
  //   _package: string
  // ) => {
  //   try {
  //     console.log("packageDetail_OTPScreen", _package);

  //     let response = await fetchApiGet(props.jwtToken, _ApiName);
  //     if (response.status === 200) {
  //       if (
  //         adNetwork.toLowerCase() === "tiktok" ||
  //         adNetwork.toLowerCase() === "tik tok"
  //       ) {
  //         tagManagerEvents("SUBSCRIBE", "econTikTok");
  //       } else if (adNetwork.toLowerCase() === "google") {
  //         tagManagerEvents("SUBSCRIBE", "econceptions");
  //       } else {
  //         tagManagerEvents("SUBSCRIBE", _utm);
  //       }
  //       let url = window.location.origin;
  //       let encodedMsisdn = base64_encode(msisdn);
  //       if (_package.toLowerCase() === "postpaid") {
  //         _package = "postpaid";
  //       } else {
  //         _package = "prepaid";
  //       }
  //       let encodedPackage = base64_encode(_package);
  //       url = `${url}/home?user=${encodedMsisdn}&package=${encodedPackage}`;

  //       if (url.includes("http:")) {
  //         url = url.replace("http", "https");
  //       }
  //       window.location.replace(url);
  //     } else {
  //       tagManagerEvents("SUBSCRIBE_FAIL", _utm);
  //       navigateToHome("")
  //     }
  //   } catch (error) {
  //       navigateToHome("")

  //   }
  // };
  const navigateToHome = (msisdn: any) => {
    let url = window.location.origin;
    url = `${url}/home`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };
  return <Awareness />;
};

export default AwarenessScreen;
